<template>
    <div class="card-list-component">
        <div class="card-list">
            <template v-for="(item,i) in list">
                <div class="item" :key="i" @click="executeAction('goto', item)">
                    <div class="item-main">
                        <div class="info-content">
                            <div class="name-content">
                                <el-popover placement="top-start" trigger="hover">
                                    <div class="fonts-12 line-height-2">
                                        {{$t('project.component_ProjectCardList_popover_label_1')}}{{item.name}}<br>
                                        <template v-if="userInfo.organizationType === 'ENTERPRISE' && item.team">
                                            {{$t('project.component_ProjectCardList_popover_label_2')}}{{item.team.teamName}}<br>
                                        </template>
                                        <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                                            {{$t('project.component_ProjectCardList_popover_label_3')}}{{item.createBy}}
                                        </template>
                                    </div>
                                    <div class="name" slot="reference">{{item.name}}</div>
                                </el-popover>
                            </div>
                            <div class="info flex flex-align-center" style="margin-bottom: 12px;">
                                <el-tooltip placement="top-start">
                                    <template slot="content">
                                        <div class="line-height-1.5" style="max-width: 400px;">
                                            <span class="language-block">{{ item.sourceLang.shortName }}</span>
                                            <svg-icon name="ib-arrow-right" style="margin:0 4px;"></svg-icon>
                                            <span class="language-block">{{ item.targetShortName }}</span>
                                        </div>
                                    </template>
                                    <span class="language" v-if="LANGUAGE_DICT">
                                        <span class="language-block">{{ item.sourceLang.shortName }}</span>
                                        <svg-icon name="ib-arrow-right" style="font-size:12px;margin:0 4px;"></svg-icon>
                                        <span class="language-block">{{ item.targetShortName }}</span>
                                    </span>
                                </el-tooltip>
                                <el-divider direction="vertical" class="margin-x-8"></el-divider>
                                <span>{{item.fileCount}} {{$t('project.component_ProjectCardList_card_label_1')}}</span>
                                <el-divider direction="vertical" class="margin-x-8"></el-divider>
                                <span>
                                    <template v-if="item.wordCount">{{item.wordCount}}</template>
                                    <span style="color:#0000004D;" v-else>—</span> {{$t('project.component_ProjectCardList_card_label_2')}}
                                </span>
                            </div>
                            <!-- <div class="info flex flex-align-center" v-if="userInfo.organizationType === 'ENTERPRISE'">
                                <div class="text-omission" style="max-width: 120px">创建人：{{item.createBy || '—'}}</div>
                                <template v-if="item.statusDesc">
                                    <el-divider direction="vertical" class="margin-x-8"></el-divider>
                                    <span :class="item.status">{{item.statusDesc}}</span>
                                </template>
                            </div> -->
                            
                            <div class="time flex flex-align-center">
                                <!-- <span class="engine margin-r-10" v-if="item.engines.length === 1">
                                    <el-tooltip :content="`${item.engines[0].name}提供预翻译`" placement="top">
                                        <svg-icon :name="'ie-'+item.engines[0].icon"></svg-icon>
                                    </el-tooltip>
                                </span>
                                <span class="engine margin-r-10" v-else>

                                </span> -->
                                <engine-group :list="item.engines"></engine-group>
                                <span class="margin-l-10" v-if="item.deadline">{{item.deadline}}</span>
                                <span class="margin-l-10" v-else>{{item.createTime}}</span>
                            </div>
                        </div>
                        <div class="right-content">
                            <template v-if="item.status === 'FINISHED'">
                                <div class="progress" style="font-size: 12px;">{{$t('project.component_ProjectCardList_card_label_3')}}</div>
                            </template>
                            <template v-else>
                                <div class="progress view" v-if="item.progress*1 === 0">{{$t('project.component_ProjectCardList_card_label_4')}}</div>
                                <template v-else>
                                    <el-tooltip :content="$t('project.component_ProjectCardList_card_label_6')" placement="top">
                                        <div class="progress">{{item.progress}}{{$t('project.component_ProjectCardList_card_label_5')}}</div>
                                    </el-tooltip>
                                </template>
                            </template>
                            <div class="action">
                                <div class="type-btn"><svg-icon name="ib-project-o"></svg-icon></div>
                                <div class="more-btn">
                                    <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command, item)">
                                        <div class="more-btn" @click.stop>
                                            <svg-icon name="ib-more"></svg-icon>
                                        </div>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="view" v-permission="`project:detail`">{{$t('project.component_ProjectCardList_card_dropdown_item_1')}}</el-dropdown-item>
                                            <template v-if="userInfo.internal">
                                                <template v-if="item.status !== 'FINISHED'">
                                                    <el-dropdown-item command="check" v-permission="`project:acceptance`">{{$t('project.component_ProjectCardList_card_dropdown_item_2')}}</el-dropdown-item>
                                                </template>
                                            </template>
                                            <!-- <el-dropdown-item command="download" v-permission="`project:download`">下载项目</el-dropdown-item> -->
                                            <el-dropdown-item command="target" v-permission="`project:download`">{{$t('project.component_ProjectCardList_card_dropdown_item_3')}}</el-dropdown-item>
                                            <el-dropdown-item command="bilingual" v-permission="`project:download`">{{$t('project.component_ProjectCardList_card_dropdown_item_4')}}
                                                <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                            </el-dropdown-item>
                                            <el-dropdown-item command="source" v-permission="`project:download`">{{$t('project.component_ProjectCardList_card_dropdown_item_5')}}</el-dropdown-item>
                                            <el-dropdown-item command="tmx" v-permission="`project:download`">{{$t('project.component_ProjectCardList_card_dropdown_item_6')}}</el-dropdown-item>
                                            <el-dropdown-item command="delete" divided v-permission="`project:delete`" style="color:#F56C6C;">{{$t('project.component_ProjectCardList_card_dropdown_item_7')}}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cover-block" @click.stop v-if="(item.preprocessStatus !== 'completed')">
                        <template v-if="item.preprocessStatus !== 'limited'">
                            <div class="fonts-14"><i class="el-icon-loading"></i> {{ item.preprocessStatusDesc }}</div>
                        </template>
                        <template v-else>
                            <div class="error fonts-20"><svg-icon name="ib-warning-o"></svg-icon></div>
                            <div class="margin-t-10 fonts-14">{{$t('project.component_ProjectCardList_card_cover_message_1')}}</div>
                            <div class="margin-t-20 fonts-14">
                                <span class="color-error cursor-pointer" v-permission="`project:delete`" @click="executeAction('delete', item)">{{$t('project.component_ProjectCardList_card_cover_btn_3')}}</span>
                                <el-tooltip :content="$t('project.component_ProjectCardList_card_cover_message_2')" placement="top" v-permission="`project:buy`">
                                    <span class="color-link cursor-pointer margin-l-20" @click="showPayCharDialog">{{$t('project.component_ProjectCardList_card_cover_btn_1')}}</span>
                                </el-tooltip>
                                <span class="color-link cursor-pointer margin-l-20" v-permission="`project:retry`" @click="executeAction('retry',item)">{{$t('project.component_ProjectCardList_card_cover_btn_2')}}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
        
    </div>
</template>
<script>
import config from '@/utils/config';
import EngineGroup from '@/components/EngineGroup';
import { mapState } from 'vuex';
import ProIcon from "@/components/icon/ProIcon";
export default {
    components: { EngineGroup, ProIcon },
    props:{
        type:{
            type:String,
            default:'',
        },
        list:{
            type:Array,
            default:[]
        }
    },
    data(){
        return{
            
        }
    },
    computed:{
        ...mapState({
            engineDist:state=>state.common.engineConfig,
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        handleMoreCommand(command,item){
            switch(command){
                case 'view':
                    this.executeAction('goto', item);
                    break;
                case 'target':
                case 'bilingual':
                case 'source':
                case 'tmx':
                    this.executeAction('download', {type: command, project: item});
                    break;
                case 'check': 
                    this.executeAction('check', item);
                    break;
                case 'delete':
                    this.executeAction('delete', item);
                    break;
            }
        },
        showPayCharDialog(){
            this.$router.push({path:'/account/vip',query: {view: 'char', child: true}});
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.card-list-component{
    .card-list{
        margin: 0 70px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
        > .item{
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 16px 20px;
            position: relative;
            cursor: pointer;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: border .3s;
            
            > .item-main{
                display:flex;
                > .info-content{
                    flex:1;
                    min-width: 0;
                    font-size:12px;
                    > .name-content{
                        margin-bottom: 20px;
                        margin-right: 20px;
                        .name{
                            font-weight: 600;
                            font-size: 15px;
                            white-space:nowrap;  
                            text-overflow:ellipsis;
                            overflow: hidden;
                        }
                    }
                    .engine{
                        svg{
                            font-size:20px;
                        }
                    }
                    .info{
                        font-size: 12px;
                        margin-bottom: 15px;
                        
                        .language{
                            display:flex;
                        }
                        .language-block{
                            display: inline-block;
                            white-space:nowrap;  
                            text-overflow:ellipsis;
                            overflow: hidden;
                            max-width: 24px;
                        }
                        .TO_START {
                            color: #F56C6C;
                        }
                        .IN_PROGRESS {
                            color: #2979FF;
                        }
                        .COMPLETED,
                        .FINISHED {
                            color: #00B670;
                        }

                    }
                    .time{
                        font-size: 12px;
                        color:#808080;
                    }
                }
                
                > .right-content{
                    width:50px;
                    display:flex;
                    flex-direction:column;
                    // justify-content: space-between;
                    .progress{
                        height:24px;
                        line-height:24px;
                        font-size:14px;
                        text-align:center;
                        background-color: #D4E8E2;
                        border-radius:50px;
                        &.view{
                            background-color: #EEEEEE;
                            color: #000000;
                            font-size: 12px;
                        }
                    }
                    .action{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                    }
                    .type-btn,.more-btn{
                        height:24px;
                        display:flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #EEEEEE;
                        border-radius:50px;
                        svg{
                            font-size:16px;
                        }
                    }
                    .more-btn{
                        display: none;
                        font-size:20px;
                    }
                }
            }
            &:hover,&:focus{
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
                > .item-main{
                    > .right-content{
                        .type-btn{
                            display: none;
                        }
                        .more-btn{
                            display: flex;
                        }
                    }
                }
            }
            
            > .cover-block{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                background: #ffffffE6;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;
                border-radius: 10px;
                cursor: default;
                .error{
                    color: $error-color;
                }
                .info{
                    margin-top: 10px;
                }
                .delete{
                    color: $a-color;
                    margin-top: 20px;
                    cursor: pointer;
                    &:hover{
                        color: $a-hover-color;
                    }
                }
            }
        }
    }
}
</style>
